export const schema = {
  "models": {
      "Blog": {
          "name": "Blog",
          "fields": {
              "id": {
                  "name": "id",
                  "isArray": false,
                  "type": "ID",
                  "isRequired": true,
                  "attributes": []
              },
              "name": {
                  "name": "name",
                  "isArray": false,
                  "type": "String",
                  "isRequired": true,
                  "attributes": []
              },
              "posts": {
                  "name": "posts",
                  "isArray": true,
                  "type": {
                      "model": "Post"
                  },
                  "isRequired": false,
                  "attributes": [],
                  "association": {
                      "connectionType": "HAS_MANY",
                      "associatedWith": "blog"
                  }
              }
          },
          "syncable": true,
          "pluralName": "Blogs",
          "attributes": [
              {
                  "type": "model",
                  "properties": {}
              }
          ]
      },
      "Post": {
          "name": "Post",
          "fields": {
              "id": {
                  "name": "id",
                  "isArray": false,
                  "type": "ID",
                  "isRequired": true,
                  "attributes": []
              },
              "date": {
                  "name": "date",
                  "isArray": false,
                  "type": "String",
                  "isRequired": true,
                  "attributes": []
              },
              "title": {
                  "name": "title",
                  "isArray": false,
                  "type": "String",
                  "isRequired": true,
                  "attributes": []
              },
              "content": {
                  "name": "content",
                  "isArray": false,
                  "type": "String",
                  "isRequired": true,
                  "attributes": []
              },
              "blog": {
                  "name": "blog",
                  "isArray": false,
                  "type": {
                      "model": "Blog"
                  },
                  "isRequired": false,
                  "attributes": [],
                  "association": {
                      "connectionType": "BELONGS_TO",
                      "targetName": "postBlogId"
                  }
              },
              "comments": {
                  "name": "comments",
                  "isArray": true,
                  "type": {
                      "model": "Comment"
                  },
                  "isRequired": false,
                  "attributes": [],
                  "association": {
                      "connectionType": "HAS_MANY",
                      "associatedWith": "post"
                  }
              }
          },
          "syncable": true,
          "pluralName": "Posts",
          "attributes": [
              {
                  "type": "model",
                  "properties": {}
              }
          ]
      },
      "Comment": {
          "name": "Comment",
          "fields": {
              "id": {
                  "name": "id",
                  "isArray": false,
                  "type": "ID",
                  "isRequired": true,
                  "attributes": []
              },
              "content": {
                  "name": "content",
                  "isArray": false,
                  "type": "String",
                  "isRequired": false,
                  "attributes": []
              },
              "post": {
                  "name": "post",
                  "isArray": false,
                  "type": {
                      "model": "Post"
                  },
                  "isRequired": false,
                  "attributes": [],
                  "association": {
                      "connectionType": "BELONGS_TO",
                      "targetName": "commentPostId"
                  }
              }
          },
          "syncable": true,
          "pluralName": "Comments",
          "attributes": [
              {
                  "type": "model",
                  "properties": {}
              }
          ]
      }
  },
  "enums": {},
  "nonModels": {},
  "version": "5ccfbcf507c2fd8ad2a83b3ba8d00db7"
};